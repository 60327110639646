* {
  font-family: 'Poppins', sans-serif;
}

@import url('http://fonts.cdnfonts.com/css/riangriung');

@media screen and (max-width: 499px) {
  .discord3{
    display: none;
  }
  
  .discord4{
    display: none;
  }
  .team1{
    margin-top: 5%;
  }

  .mainBanner{
    width: 100%;
   }

  .iconsMob {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 7%;
  }

  .icons{
    display: none;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: 23%;
    }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/footer.jpg') ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap6 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/sea.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks{
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    flex-flow: column nowrap;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyHRmM img{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyRm{
    display: none;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
    background-color: rgba(0, 92, 8, 0.664);
    }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 15px;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
    z-index: 1000000;
  }
  
  .logoF{
    color: white;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }
  
  .logoF img{
    width: 70%;
  }
  
  .intro img {
    width: 90%;
    z-index: 1000000000;

  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: 3%;
  }

  .connect{
    display: none;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Luckiest Guy', cursive;
    align-items: center;
  }
  
  .connectMobile div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Luckiest Guy', cursive;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .connectMobile div:hover{
    color: #0067ca;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #96ec1f;
    color: #3c6622;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
    font-family: 'Luckiest Guy', cursive;
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
    background-color: #1eccc3;

  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #ffd94d;
    color: #000000;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    color: #2b2b2b;
    background-color: #edfa5c;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 15px;
    font-weight: bold;
   }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 15px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 16px;
    font-weight: 600;
    background: rgba(47, 99, 168, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
    text-shadow: #47474770 2px 3px 1px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(0, 0, 0, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: url('./assets/team.jpg'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  .rmMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
   }

  .rmboxes{
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .rmBox {
    text-align: left;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    width: 250px;
  }
  
  .rmBox2 {
    text-align: left;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 10%;
    font-size: 13px;
  }
  
  .year {
    font-size: 16px;
    font-family: 'Luckiest Guy', cursive;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
   }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 100px;
    height: 143px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-top: -100%;
    margin-left: -45%;

  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 300vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    display: none;
  }

  .rmP2 {
     margin-right: auto;
    justify-content: center;
    width: 15%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25%;
    width: 150%;
    margin-left: -30%;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #1eccc3;

  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 2.5rem;
    background-color: #aa5620;
    padding-left: 100%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 2.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 16px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark{
    width: 250px;
    height: 358px;
    z-index: 1;
    margin-left: auto;
    margin-top: -10%;
  }
  
  .teamMain{
    display: flex;
  }
  
  
  .memName {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 18px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 250px;
    height: 250px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2{
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
    font-size: 14px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .discord3{
    display: none;
  }
  
  .discord4{
    display: none;
  }
  .team1{
    margin-top: 5%;
  }

  .mainBanner{
    width: 100%;
   }

  .iconsMob {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 7%;
  }

  .icons{
    display: none;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: 23%;
    }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/footer.jpg') ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap6 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/sea.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks{
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    flex-flow: column nowrap;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyHRmM img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyRm{
    display: none;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
    background-color: rgba(0, 92, 8, 0.664);
    }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 15px;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
    z-index: 1000000;
  }
  
  .logoF{
    color: white;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }
  
  .logoF img{
    width: 90%;
  }
  
  .intro img {
    width: 80%;
    z-index: 1000000000;

  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: 3%;
  }

  .connect{
    display: none;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Luckiest Guy', cursive;
    align-items: center;
  }
  
  .connectMobile div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Luckiest Guy', cursive;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .connectMobile div:hover{
    color: #0067ca;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #96ec1f;
    color: #3c6622;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
    font-family: 'Luckiest Guy', cursive;
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
    background-color: #1eccc3;

  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #ffd94d;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    color: #2b2b2b;
    background-color: #edfa5c;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
   }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 18px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 18px;
    font-weight: 600;
    background: rgba(47, 99, 168, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
    text-shadow: #47474770 2px 3px 1px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(0, 0, 0, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: url('./assets/team.jpg'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  .rmMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
   }

  .rmboxes{
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .rmBox {
    text-align: left;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    width: 350px;
  }
  
  .rmBox2 {
    text-align: left;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 10%;
    font-size: 15px;
  }
  
  .year {
    font-size: 18px;
    font-family: 'Luckiest Guy', cursive;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
   }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 100px;
    height: 143px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-top: -100%;
    margin-left: -30%;

  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 300vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    display: none;
  }

  .rmP2 {
     margin-right: auto;
    justify-content: center;
    width: 15%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25%;
    width: 150%;
    margin-left: -30%;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #1eccc3;

  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 2.5rem;
    background-color: #aa5620;
    padding-left: 100%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 2.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 16px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark{
    width: 250px;
    height: 358px;
    z-index: 1;
    margin-left: auto;
    margin-top: -10%;
  }
  
  .teamMain{
    display: flex;
  }
  
  
  .memName {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 20px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 16px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 250px;
    height: 250px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2{
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .discord3{
    display: none;
  }
  
  .discord4{
    display: none;
  }
  .mainBanner{
    width: 100%;
   }

  .iconsMob {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 7%;
  }

  .icons{
    display: none;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: 23%;
    margin-right: 15%;
    }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/footer.jpg') ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap6 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/sea.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks{
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    flex-flow: column nowrap;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyHRmM img{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .storyRm{
    display: none;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
    background-color: rgba(0, 92, 8, 0.664);
    }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 16.5px;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
    z-index: 1000000;
  }
  
  .logoF{
    color: white;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }
  
  .logoF img{
    width: 60%;
  }
  
  .intro img {
    width: 55%;
    z-index: 1000000000;

  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: 1%;
  }

  .connect{
    display: none;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Luckiest Guy', cursive;
    align-items: center;
  }
  
  .connectMobile div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Luckiest Guy', cursive;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .connectMobile div:hover{
    color: #0067ca;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #96ec1f;
    color: #3c6622;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
    font-family: 'Luckiest Guy', cursive;
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
    background-color: #1eccc3;

  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #ffd94d;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    color: #2b2b2b;
    background-color: #edfa5c;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
   }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 22px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background: rgba(47, 99, 168, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
    text-shadow: #47474770 2px 3px 1px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(0, 0, 0, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    font-weight: bold;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: url('./assets/team.jpg'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  .rmMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
   }

  .rmboxes{
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .rmBox {
    text-align: left;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    width: 400px;
  }
  
  .rmBox2 {
    text-align: left;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 10%;
  }
  
  .year {
    font-size: 18px;
    font-family: 'Luckiest Guy', cursive;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
   }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-top: -100%;
    margin-left: -17%;

  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 300vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
    display: none;
  }

  .rmP2 {
     margin-right: auto;
    justify-content: center;
    width: 15%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25%;
    width: 100%;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #1eccc3;

  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark{
    width: 300px;
    height: 429px;
    z-index: 1;
    margin-left: auto;
    margin-top: -10%;
  }
  
  .teamMain{
    display: flex;
  }
  
  
  .memName {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 20px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 16px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 250px;
    height: 250px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2{
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .storyHRmM{
    display: none;
  }
  .discord3{
    display: none;
  }
  
  .discord4{
    display: none;
  }

  .mainBanner{
    width: 100%;
  }

  .iconsMob {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 7%;
  }

  .icons{
    display: none;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10%;
  }
  
  .rmP2 {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/footer.jpg') ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }
  
  .boxWrap6 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('./assets/sea.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }
  
  .sharks{
    width: 100%;
  }
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    flex-flow: row nowrap;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH img{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .storyRm img{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
    background-color: rgba(0, 92, 8, 0.664);
    margin-right: -2%;
   }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 17px;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
    z-index: 1000000;
  }
  
  .logoF{
    color: white;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }
  
  .logoF img{
    width: 60%;
  }
  
  .intro img {
    width: 55%;
    z-index: 1000000000;

  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: 1%;
  }

  .connect{
    display: none;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Luckiest Guy', cursive;
    align-items: center;
  }
  
  .connectMobile div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Luckiest Guy', cursive;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .connectMobile div:hover{
    color: #0067ca;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #96ec1f;
    color: #3c6622;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    transition: transform .2s;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
    font-family: 'Luckiest Guy', cursive;
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
    background-color: #1eccc3;

  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #ffd94d;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    color: #2b2b2b;
    background-color: #edfa5c;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
   }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 133px;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 22px;
    font-family: 'Luckiest Guy', cursive;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background: rgba(47, 99, 168, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
    text-shadow: #47474770 2px 3px 1px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(0, 0, 0, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    font-weight: bold;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: url('./assets/team.jpg'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
   }
  
  .year {
     font-size: 18px;
    font-family: 'Luckiest Guy', cursive;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 60%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 60%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 15%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #1eccc3;

  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .shark{
    width: 300px;
    height: 429px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain{
    display: flex;
  }
  
  
  .memName {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 20px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 250px;
    height: 250px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
    font-family: 'Luckiest Guy', cursive;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2{
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .discord3{
    display: none;
  }
  
  .discord4{
    display: none;
  }
  .storyHRmM{
    display: none;
  }
  .iconsMob{
  display: none;
}

.mainBanner{
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10%;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgb(0, 0, 0);
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/footer.jpg') ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap4 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap6 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/team.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/sea.gif') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap3 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #1eccc3;
  margin-bottom: -20%;
}

.sharks{
  width: 100%;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
 }

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 90%;
  flex-flow: row nowrap;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;
}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.storyRm img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
  background-color: rgba(0, 92, 8, 0.664);
  margin-right: -2%;
 }

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);
  
}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 18px;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF{
  color: white;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF img{
  width: 50%;
}

.intro img {
  width: 50%;
  z-index: 100000;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Luckiest Guy', cursive;
  align-items: center;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Luckiest Guy', cursive;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;

}

.connect div:hover{
  color: #0067ca;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #96ec1f;
  color: #3c6622;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;
  font-family: 'Luckiest Guy', cursive;
  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
 }


.wallet2 {
  z-index: 1000;
  background-color: #ffd94d;
  color: #000000;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  color: #2b2b2b;
  background-color: #edfa5c;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
 }

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 25px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background: rgba(47, 99, 168, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
  text-shadow: #47474770 2px 3px 1px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(0, 0, 0, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
  font-weight: bold;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: url('./assets/team.jpg'); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
 }

.year {
   font-size: 18px;
  font-family: 'Luckiest Guy', cursive;
  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 33.33%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 33.33%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 130px;
  height: 186px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 33%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 270vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  background: url('./assets/bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}


* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: #aa5620;
  padding-left: 100%;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 18px;
  color: #ffffff;
  font-weight: 800;
}

.shark{
  width: 400px;
  height: 572px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain{
  display: flex;
}


.memName {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 20px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: white;
  font-size: 17px;
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-shadow: 0 0 10px #FFFFFF;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 250px;
  height: 250px;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2{
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {

.discord3{
  display: none;
}

.discord4{
  display: none;
}

  .storyHRmM{
    display: none;
  }
  .iconsMob{
  display: none;
}

.mainBanner{
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10%;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgb(0, 0, 0);
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/footer.jpg') ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap4 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap6 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/team.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/sea.gif') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap3 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #1eccc3;
  margin-bottom: -20%;
}

.sharks{
  width: 100%;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
 }

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 90%;
  flex-flow: row nowrap;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;
}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.storyRm img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(1, 32, 4, 0.445);
  background-color: rgba(0, 92, 8, 0.664);
  margin-right: -2%;
 }

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);
  
}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 26px;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF{
  color: white;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF img{
  width: 50%;
}

.intro img {
  width: 60%;
  z-index: 100000;
  margin-top: 3%;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord2 {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord2:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Luckiest Guy', cursive;
  align-items: center;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Luckiest Guy', cursive;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;

}

.connect div:hover{
  color: #0067ca;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #96ec1f;
  color: #3c6622;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;
  font-family: 'Luckiest Guy', cursive;
  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
 }


.wallet2 {
  z-index: 1000;
  background-color: #ffd94d;
  color: #000000;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  color: #2b2b2b;
  background-color: #edfa5c;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 23px;
  font-weight: bold;
 }

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 32px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 25px;
  font-weight: 600;
  background: rgba(47, 99, 168, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
  text-shadow: #47474770 2px 3px 1px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(0, 0, 0, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: url('./assets/team.jpg'); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
  font-size: 22px;
 }

.year {
   font-size: 25px;
  font-family: 'Luckiest Guy', cursive;
  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 45%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 45%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 180px;
  height: 257px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 30%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 200vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  background: url('./assets/bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}


* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 5rem;
  background-color: #aa5620;
  padding-left: 100%;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 5rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 22px;
  color: #ffffff;
  font-weight: 800;
}

.shark{
  width: 500px;
  height: 715px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain{
  display: flex;
}

.memName2 {
  font-size: 20px;
}

.memName {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 28px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: white;
  font-size: 22px;
  text-align: justify;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-shadow: 0 0 10px #FFFFFF;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 350px;
  height: 350px;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2{
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .discord3 {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord3:hover {
    transform: scale(1.1);
  }
  
  .discord4 {
    padding-right: 20px;
    display: none;
  }
  .storyHRmM{
    display: none;
  }
  .iconsMob{
  display: none;
}

.mainBanner{
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10%;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord {
  display: none;
}

.discord2 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgb(0, 0, 0);
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/footer.jpg') ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap4 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap6 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/team.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/sea.gif') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap3 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #1eccc3;
  margin-bottom: -20%;
}

.sharks{
  width: 100%;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
 }

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 90%;
  flex-flow: row nowrap;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;
}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.storyRm img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 40px;
  border-radius: 50px;
  box-shadow: -20px 20px rgba(1, 32, 4, 0.445);
  background-color: rgba(0, 92, 8, 0.664);
  margin-right: -2%;
 }

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 40px;
  border-radius: 50px;
  box-shadow: -20px 20px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);
  
}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 32px;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF{
  color: white;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF img{
  width: 50%;
}

.intro img {
  width: 60%;
  z-index: 100000;
  margin-top: 3%;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord3 {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord3:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Luckiest Guy', cursive;
  align-items: center;
}

.connect div {
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Luckiest Guy', cursive;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;

}

.connect div:hover{
  color: #0067ca;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #96ec1f;
  color: #3c6622;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;
  font-family: 'Luckiest Guy', cursive;
  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
 }


.wallet2 {
  z-index: 1000;
  background-color: #ffd94d;
  color: #000000;
  padding: 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 38px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  color: #2b2b2b;
  background-color: #edfa5c;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 35px;
  font-weight: bold;
 }

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 42px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 33px;
  font-weight: 600;
  background: rgba(47, 99, 168, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1.5rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
  text-shadow: #47474770 2px 3px 1px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 60%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(0, 0, 0, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: url('./assets/team.jpg'); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
  font-size: 30px;
 }

.year {
   font-size: 37px;
  font-family: 'Luckiest Guy', cursive;
  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 45%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 45%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 250px;
  height: 358px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 12%;
  margin-right: auto;
  display: block;
  margin-left: 26%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 200vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  background: url('./assets/bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}


* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 6rem;
  background-color: #aa5620;
  padding-left: 100%;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 6rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 30px;
  color: #ffffff;
  font-weight: 800;
}

.shark{
  width: 600px;
  height: 858px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain{
  display: flex;
}

.memName2 {
  font-size: 25px;
}

.memName {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 35px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: white;
  font-size: 30px;
  text-align: justify;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-shadow: 0 0 10px #FFFFFF;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 500px;
  height: 500px;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2{
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}
}

@media screen and (min-width: 3840px) {
  .discord4 {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord4:hover {
    transform: scale(1.1);
  }
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  .storyHRmM{
    display: none;
  }
  .iconsMob{
  display: none;
}

.mainBanner{
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10%;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord {
  display: none;
}

.discord2 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgb(0, 0, 0);
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/introductionBg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/footer.jpg') ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap4 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.boxWrap6 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/team.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('./assets/sea.gif') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap3 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #1eccc3;
  margin-bottom: -20%;
}

.sharks{
  width: 100%;
}

.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
 }

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 90%;
  flex-flow: row nowrap;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;
}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.storyRm img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 50px;
  border-radius: 50px;
  box-shadow: -30px 30px rgba(1, 32, 4, 0.445);
  background-color: rgba(0, 92, 8, 0.664);
  margin-right: -2%;
 }

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 50px;
  border-radius: 50px;
  box-shadow: -30px 30px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);
  
}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 50px;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF{
  color: white;
  text-align: center;
  font-family: 'Riangriung', sans-serif;
}

.logoF img{
  width: 50%;
}

.intro img {
  width: 60%;
  z-index: 100000;
  margin-top: 3%;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord2 {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: none;
}

.discord2:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Luckiest Guy', cursive;
  align-items: center;
}

.connect div {
  margin-left: 30px;
  margin-right: 30px;
  font-family: 'Luckiest Guy', cursive;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;

}

.connect div:hover{
  color: #0067ca;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #96ec1f;
  color: #3c6622;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  transition: transform .2s;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;
  font-family: 'Luckiest Guy', cursive;
  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
 }


.wallet2 {
  z-index: 1000;
  background-color: #ffd94d;
  color: #000000;
  padding: 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 50px;
  border-radius: 50px;
  padding-left: 50px;
  padding-right: 50px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  color: #2b2b2b;
  background-color: #edfa5c;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 50px;
  font-weight: bold;
 }

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 133px;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 60px;
  font-family: 'Luckiest Guy', cursive;
  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 50px;
  font-weight: 600;
  background: rgba(47, 99, 168, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1.5rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
  text-shadow: #47474770 2px 3px 1px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 60%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(0, 0, 0, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 45px;
  font-weight: bold;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: url('./assets/team.jpg'); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
  font-size: 35px;
 }

.year {
   font-size: 48px;
  font-family: 'Luckiest Guy', cursive;
  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 45%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 45%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 300px;
  height: 429px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 12%;
  margin-right: auto;
  display: block;
  margin-left: 32%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 200vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
  width: 40%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  background: url('./assets/bg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}


* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 6rem;
  background-color: #aa5620;
  padding-left: 100%;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 6rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 30px;
  color: #ffffff;
  font-weight: 800;
}

.shark{
  width: 850px;
  height: 1216px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain{
  display: flex;
}

.memName2 {
  font-size: 40px;
}

.memName {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 50px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: white;
  font-size: 45px;
  text-align: justify;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-shadow: 0 0 10px #FFFFFF;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 750px;
  height: 750px;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {
  font-family: 'Luckiest Guy', cursive;
  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2{
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}
}